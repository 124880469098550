export const HYPIXEL = {
	GAMES : {
		ARCADE: 'Arcade Games',
		ARENA: 'Arena Brawl',
		BATTLEGROUND: 'Warlords',
		BEDWARS: 'Bed Wars',
		BUILD_BATTLE: 'Build Battle',
		DUELS: 'Duels',
		GINGERBREAD: 'Turbo Kart Racers',
		HOUSING: 'Housing',
		LEGACY: 'Classic Games',
		MAIN: 'Main',
		MCGO: 'Cops and Crims',
		MURDER_MYSTERY: 'Murder Mystery',
		PAINTBALL: 'Paintball',
		PIT: 'Pit',
		PROTOTYPE: 'Prototype',
		QUAKECRAFT: 'Quakecraft',
		SKYBLOCK: 'SkyBlock',
		SKYWARS: 'SkyWars',
		SMP: 'SMP',
		SPEED_UHC: 'Speed UHC',
		SUPER_SMASH: 'Smash Heroes',
		SURVIVAL_GAMES: 'Blitz SG',
		TNTGAMES: 'TNT Games',
		TOURNAMENT: 'Tournament Hall',
		UHC: 'UHC Champions',
		VAMPIREZ: 'VampireZ',
		WALLS: 'Walls',
		WALLS3: 'Mega Walls',
		WOOL_GAMES: 'Wool Wars',
	},
	QUESTS: {
		arcade: 'Arcade Games',
		arena: 'Arena Brawl',
		battleground: 'Warlords',
		bedwars: 'Bed Wars',
		buildbattle: 'Build Battle',
		duels: 'Duels',
		gingerbread: 'Turbo Kart Racers',
		hungergames: 'Blitz SG',
		mcgo: 'Cops and Crims',
		murdermystery: 'Murder Mystery',
		paintball: 'Paintball',
		prototype: 'Pit',
		quake: 'Quakecraft',
		skywars: 'SkyWars',
		supersmash: 'Smash Heroes',
		tntgames: 'TNT Games',
		uhc: 'UHC Champions',
		vampirez: 'VampireZ',
		walls: 'Walls',
		walls3: 'Mega Walls',
		woolgames: 'Wool Wars',
	},
	ACHIEVEMENTS: {
		arcade: 'Arcade Games',
		arena: 'Arena Brawl',
		bedwars: 'Bed Wars',
		blitz: 'Blitz SG',
		buildbattle: 'Build Battle',
		christmas2017: 'Christmas',
		copsandcrims: 'Cops and Crims',
		duels: 'Duels',
		easter: 'Easter',
		general: 'General',
		gingerbread: 'Turbo Kart Racers',
		halloween2017: 'Halloween',
		housing: 'Housing',
		murdermystery: 'Murder Mystery',
		paintball: 'Paintball',
		pit: 'Pit',
		quake: 'Quakecraft',
		skyblock: 'SkyBlock',
		skywars: 'SkyWars',
		speeduhc: 'Speed UHC',
		summer: 'Summer',
		supersmash: 'Smash Heroes',
		tntgames: 'TNT Games',
		uhc: 'UHC Champions',
		vampirez: 'VampireZ',
		walls: 'Walls',
		walls3: 'Mega Walls',
		warlords: 'Warlords',
		woolgames: 'Wool Wars',
	},
	MULTIPLIER: [
		{level: 0, value: 1},
		{level: 5, value: 1.5},
		{level: 10, value: 2},
		{level: 15, value: 2.5},
		{level: 20, value: 3},
		{level: 25, value: 3.5},
		{level: 30, value: 4},
		{level: 40, value: 4.5},
		{level: 50, value: 5},
		{level: 100, value: 5.5},
		{level: 125, value: 6},
		{level: 150, value: 6.5},
		{level: 200, value: 7},
		{level: 250, value: 8},
	],
	RANKPRIORITY: [
		undefined,
		'NONE',
		'VIP',
		'VIP_PLUS',
		'MVP',
		'MVP_PLUS',
		'SUPERSTAR',
		'YOUTUBER',
		'HELPER',
		'MODERATOR',
		'GAME_MASTER',
		'ADMIN'
	],
	RANKMULTIPLIER: {
		VIP: {value: 2, name: 'VIP'},
		VIP_PLUS: {value: 3, name: 'VIP+'},
		MVP: {value: 4, name: 'MVP'},
		MVP_PLUS: {value: 5, name: 'MVP+'},
		YOUTUBER: {value: 7, name: 'YouTuber'},
	},
	TOTALCOINS: [
		'stats.Arcade.coins',
		'stats.Arena.coins',
		'stats.Bedwars.coins',
		'stats.HungerGames.coins',
		'stats.BuildBattle.coins',
		'stats.MCGO.coins',
		'stats.Duels.coins',
		'stats.Walls3.coins',
		'stats.MurderMystery.coins',
		'stats.Paintball.coins',
		'stats.Quake.coins',
		'stats.SkyWars.coins',
		'stats.SuperSmash.coins',
		'stats.SpeedUHC.coins',
		'stats.TNTGames.coins',
		'stats.GingerBread.coins',
		'stats.UHC.coins',
		'stats.VampireZ.coins',
		'stats.Walls.coins',
		'stats.Battleground.coins',
		'stats.TrueCombat.coins', // Crazy Walls (removed)
		'stats.SkyClash.coins', // SkyClash (removed)
		'stats.WoolGames.coins',
	],
	TOTALKILLS: [
		'stats.Arcade.kills_dayone',
		'stats.Arcade.kills_oneinthequiver',
		'achievements.arcade_ctw_slayer',
		'stats.Arcade.kills_dragonwars2',
		'stats.Arcade.sw_kills',
		'stats.Arcade.kills_throw_out',
		'stats.Arcade.kills_mini_walls',
		'stats.Arcade.final_kills_mini_walls',
		'stats.Arena.kills_1v1',
		'stats.Arena.kills_2v2',
		'stats.Arena.kills_4v4',
		'stats.Bedwars.kills_bedwars',
		'stats.Bedwars.final_kills_bedwars',
		'stats.HungerGames.kills',
		'stats.MCGO.kills',
		'stats.MCGO.kills_deathmatch',
		'stats.Duels.kills',
		'stats.Duels.bridge_kills',
		'stats.Walls3.kills',
		'stats.Walls3.final_kills',
		'stats.MurderMystery.kills',
		'stats.Paintball.kills',
		'stats.Pit.pit_stats_ptl.kills',
		'stats.Quake.kills',
		'stats.Quake.kills_teams',
		'stats.SkyWars.kills',
		'stats.SuperSmash.kills',
		'stats.SpeedUHC.kills',
		'stats.TNTGames.kills_pvprun',
		'stats.TNTGames.kills_tntag',
		'stats.TNTGames.kills_capture',
		'stats.UHC.kills_solo',
		'stats.UHC.kills',
		'stats.UHC.kills_red vs blue',
		'stats.UHC.kills_no diamonds',
		'stats.UHC.kills_vanilla doubles',
		'stats.UHC.kills_brawl',
		'stats.UHC.kills_solo brawl',
		'stats.UHC.kills_duo brawl',
		'stats.VampireZ.vampire_kills',
		'stats.VampireZ.human_kills',
		'stats.Walls.kills',
		'stats.Battleground.kills', 
		'stats.TrueCombat.kills', // Crazy Walls (removed)
		'stats.SkyClash.kills', // SkyClash (removed)
		'stats.WoolGames.wool_wars.stats.kills',
	],
	TOTALWINS: [
		'stats.Arcade.wins_dayone',
		'stats.Arcade.wins_oneinthequiver',
		'stats.Arcade.wins_dragonwars2',
		'stats.Arcade.wins_easter_simulator',
		'stats.Arcade.wins_ender',
		'stats.Arcade.wins_farm_hunt',
		'stats.Arcade.wins_soccer',
		'stats.Arcade.sw_game_wins',
		'stats.Arcade.wins_grinch_simulator_v2',
		'stats.Arcade.wins_halloween_simulator',
		'stats.Arcade.seeker_wins_hide_and_seek',
		'stats.Arcade.hider_wins_hide_and_seek',
		'stats.Arcade.wins_hole_in_the_wall',
		'stats.Arcade.wins_simon_says',
		'stats.Arcade.wins_party',
		'stats.Arcade.wins_party_2',
		'stats.Arcade.wins_party_3',
		'stats.Arcade.wins_draw_their_thing',
		'stats.Arcade.wins_scuba_simulator',
		'stats.Arcade.wins_throw_out',
		'stats.Arcade.wins_mini_walls',
		'stats.Arcade.wins_zombies',
		'stats.Arena.wins_1v1',
		'stats.Arena.wins_2v2',
		'stats.Arena.wins_4v4',
		'stats.Bedwars.wins_bedwars',
		'stats.HungerGames.wins',
		'stats.BuildBattle.wins',
		'stats.MCGO.game_wins',
		'stats.MCGO.game_wins_deathmatch',
		'stats.Duels.wins',
		'stats.Walls3.wins',
		'stats.MurderMystery.wins',
		'stats.Paintball.wins',
		'stats.Quake.wins',
		'stats.Quake.wins_teams',
		'stats.SkyWars.wins',
		'stats.SkyWars.wins_lab',
		'stats.SuperSmash.wins',
		'stats.SpeedUHC.wins',
		'stats.TNTGames.wins', // Every TNT Game except PVP Run
		'stats.TNTGames.wins_pvprun',
		'stats.GingerBread.wins',
		'stats.UHC.wins_solo',
		'stats.UHC.wins',
		'stats.UHC.wins_red vs blue',
		'stats.UHC.wins_no diamonds',
		'stats.UHC.wins_vanilla doubles',
		'stats.UHC.wins_brawl',
		'stats.UHC.wins_solo brawl',
		'stats.UHC.wins_duo brawl',
		'stats.VampireZ.vampire_wins',
		'stats.VampireZ.human_wins',
		'stats.Walls.wins',
		'stats.Battleground.wins',
		'stats.TrueCombat.wins', // Crazy Walls (removed)
		'stats.SkyClash.wins', // SkyClash (removed)
		'stats.WoolGames.wool_wars.stats.wins',
	],
}