export const MEGAWALLS = {
	TITLE: 'Mega Walls',
	CLASSES: [
		{id: 'arcanist', name: 'Arcanist', difficulty: '1'},
		{id: 'assassin', name: 'Assassin', difficulty: '2'},
		{id: 'automaton', name: 'Automaton', difficulty: '4'},
		{id: 'blaze', name: 'Blaze', difficulty: '2'},
		{id: 'cow', name: 'Cow', difficulty: '1'},
		{id: 'creeper', name: 'Creeper', difficulty: '3'},
		{id: 'dreadlord', name: 'Dreadlord', difficulty: '1'},
		{id: 'enderman', name: 'Enderman', difficulty: '2'},
		{id: 'golem', name: 'Golem', difficulty: '1'},
		{id: 'herobrine', name: 'Herobrine', difficulty: '1'},
		{id: 'hunter', name: 'Hunter', difficulty: '2'},
		{id: 'moleman', name: 'Moleman', difficulty: '3'},
		{id: 'phoenix', name: 'Phoenix', difficulty: '3'},
		{id: 'pigman', name: 'Pigman', difficulty: '1'},
		{id: 'pirate', name: 'Pirate', difficulty: '3'},
		{id: 'renegade', name: 'Renegade', difficulty: '4'},
		{id: 'shaman', name: 'Shaman', difficulty: '2'},
		{id: 'shark', name: 'Shark', difficulty: '3'},
		{id: 'skeleton', name: 'Skeleton', difficulty: '3'},
		{id: 'snowman', name: 'Snowman', difficulty: '4'},
		{id: 'spider', name: 'Spider', difficulty: '3'},
		{id: 'squid', name: 'Squid', difficulty: '2'},
		{id: 'werewolf', name: 'Werewolf', difficulty: '2'},
		{id: 'zombie', name: 'Zombie', difficulty: '1'},
	],
	DIFFICULTIES: {
		'1': 'green',
		'2': 'yellow',
		'3': 'red',
		'4': 'darkred',
	},
	MODES: [
		{id: '_standard', name: 'Normal'},
		{id: '_face_off', name: 'Face Off'},
		{id: '_gvg', name: 'Casual Brawl'},
		// Mega Walls does not get an 'Overall' category cause the data from the API doesn't add up
	]
}